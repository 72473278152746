import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import { Link } from "gatsby"

import ImageRenderer from "../components/imageRenderer"

const CategoryTemplate = ({ data }) => {
  const category = data.categories
  const posts = data.allPosts.edges

  return (
    <Layout style={{ maxWidth: "1100px" }}>
      <SEO
        title={`${category.name} | Iqara Islam`}
        description={category.description}
        isCategory={true}
      />

      <div className="main main-category" style={{ marginBottom: 30 }}>
        <div className="category-breadcrumb">
          <Link to="/">
            <h2>Home</h2>
          </Link>{" "}
          / <h2 dangerouslySetInnerHTML={{ __html: category.name }}></h2>
        </div>

        <div className="posts">
          {posts.map(post => (
            <Link to={post.node.slug} className="post" key={post.node.slug}>
              <ImageRenderer imageData={post.node.imageUrl} />
              <h2 dangerouslySetInnerHTML={{ __html: post.node.name }}></h2>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query($id: String!, $slug: String!) {
    categories(id: { eq: $id }) {
      name
      slug
      description
    }

    allPosts(
      sort: { fields: [createdAt], order: DESC }
      filter: { category: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }
  }
`
