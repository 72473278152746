import React from "react"

const ImageRenderer = ({ imageData, isAMP = false, className }) => {
  if (!imageData) return null
  if (!imageData.localFile) {
    if (imageData.source_url) {
      return (
        <figure className={className}>
          <img
            src={imageData ? imageData.source_url : ""}
            srcSet={imageData ? imageData.source_url : ""}
            alt={imageData.alt_text ? imageData.alt_text : ""}
          />
        </figure>
      )
    } else {
      return (
        <figure className={className}>
          <img
            src={imageData ? imageData : ""}
            srcSet={imageData ? imageData : ""}
            alt={imageData.alt_text ? imageData.alt_text : ""}
          />
        </figure>
      )
    }
  }

  if (!imageData.localFile.childImageSharp) return null

  return (
    <figure className={className}>
      <img
        src={imageData ? imageData.localFile.childImageSharp.fixed.src : ""}
        srcSet={
          imageData ? imageData.localFile.childImageSharp.fixed.srcSet : ""
        }
        alt={imageData.alt_text}
        // sizes="(max-width: 300px) 100vw, 300px"
      />
      {/* <figcaption>{data.wordpressPost.featured_media.alt_text}</figcaption> */}
    </figure>
  )
}

export default ImageRenderer
